<template>
  <div>
    <p class="account-title" style="margin-bottom: 8px">Подписки</p>
    <span style="font-size: 16px; margin-bottom: 20px"
      >Ответьте на вопросы, чтобы настроить вашу ленту предпочтений. Вы можете
      изменить их в любой момент</span
    >

    <div class="d-flex subscriptions-list" style="margin-top: 20px; gap: 20px">
      <subscription-card-view />
      <subscription-card-view />
    </div>
  </div>
</template>

<script>
import SubscriptionCardView from "./components/SubscriptionCard.vue";
export default {
  data() {
    return {};
  },
  components: {
    SubscriptionCardView,
  },
};
</script>

<style lang="scss">
.subscriptions-list {
  @media screen and (max-width: 991.98px) {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 743px) {
    display: grid !important;
    grid-template-columns: 1fr;
  }

  .card {
    margin-bottom: 0 !important;
  }
}
</style>
