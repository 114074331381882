<template>
  <b-card
    no-body
    class="subscription-card"
    style="
      padding: 20px 85px 20px 20px;
      background: linear-gradient(180deg, #260a64 25.1%, #000000 160.79%);
      color: #fff;
    "
  >
    <b-card-title style="color: #fff"> Premium-предложения </b-card-title>

    <ul
      class="d-flex flex-column"
      style="gap: 18px; padding: 0; list-style: none; font-size: 16px"
    >
      <li>
        <span
          class="check-icon"
          style="
            display: inline-block;
            margin-right: 8px;
            vertical-align: middle;
          "
        ></span>
        Access to all basic features
      </li>
      <li>
        <span
          class="check-icon"
          style="
            display: inline-block;
            margin-right: 8px;
            vertical-align: middle;
          "
        ></span>
        Access to all basic features
      </li>
      <li>
        <span
          class="check-icon"
          style="
            display: inline-block;
            margin-right: 8px;
            vertical-align: middle;
          "
        ></span>
        Access to all basic features
      </li>
    </ul>
    <b-card-text
      style="color: #fff; font-size: 24px; font-weight: 700; margin-top: 18px"
    >
      500
      <span
        class="credit-icon"
        style="display: inline-block; margin-right: 4px"
      ></span>
      <span style="color: #746985">/ в мес</span>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.subscription-card {
  @media screen and (max-width: 991.98px) {
    padding: 20px !important;
  }
}
</style>
